import {forwardRef, MouseEvent, PropsWithChildren} from "react";
import {css, SerializedStyles, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {elevation} from "@pg-design/elevation";
import {borderRadius, m, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {useFieldStateColor} from "../../hooks/use_field_state_color";
import {IFieldState} from "../../types";

export type IInputWrapperProps = PropsWithChildren<{
    fieldState?: IFieldState;
    detail?: string;
    leftElement?: JSX.Element | string;
    rightElement?: JSX.Element | string;
    elementCustomStyle?: SerializedStyles | string;
    rightElementCss?: SerializedStyles;
    className?: string;
    noPadding?: boolean; // TODO: temp fix, consider defining padding in inner components like in an actual input field or whatever is using the wrapper
    inputWrapCss?: SerializedStyles;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
    position?: ICssPosition;
}>;

export const InputWrapper = forwardRef<HTMLDivElement, IInputWrapperProps>((props: IInputWrapperProps, forwardedRef) => {
    const {children, fieldState, detail, leftElement, rightElement, elementCustomStyle, className, noPadding, inputWrapCss, onClick, position} = props;
    const {getFieldStateColor} = useFieldStateColor();

    return (
        <InputWrapperBase
            fieldState={fieldState}
            fieldStateColor={getFieldStateColor(fieldState)}
            className={className}
            ref={forwardedRef}
            noPadding={noPadding}
            onClick={onClick}
            position={position}
        >
            {leftElement && <div css={[leftElementStyle, elementCustomStyle]}>{leftElement}</div>}
            <div css={[inputWrapStyle, inputWrapCss]}>
                {children}
                {detail && (
                    <span css={detailStyle}>
                        <Text as="span" variant="info_txt_3">
                            {detail}
                        </Text>
                    </span>
                )}
            </div>
            {rightElement && <div css={[rightElementStyle, elementCustomStyle]}>{rightElement}</div>}
        </InputWrapperBase>
    );
});

/*
    Styles
 */
export const inputWrapperHorizontalPaddingSize = 2;
export const inputWrapperVerticalPaddingSize = 1.5;
type ICssPosition = "static" | "relative" | "absolute" | "sticky" | "fixed" | "inherit" | "initial" | "revert" | "revert-layer" | "unset";

const InputWrapperBase = styled.div<{
    fieldState?: IFieldState;
    fieldStateColor: string;
    noPadding?: boolean;
    position?: ICssPosition;
}>`
    &:has(input:-webkit-autofill) {
        padding: 0;

        & input {
            ${p(inputWrapperVerticalPaddingSize, inputWrapperHorizontalPaddingSize)};
            ${borderRadius(1)};
        }
    }
    ${({theme, fieldStateColor, fieldState, noPadding, position}) => css`
        display: flex;
        background: ${fieldState && fieldState === "disabled" ? theme.colors.gray["100"] : "#fff"};
        ${borderRadius(1)};

        ${!noPadding &&
        css`
            ${p(inputWrapperVerticalPaddingSize, inputWrapperHorizontalPaddingSize)};
        `};

        ${position &&
        css`
            position: ${position};
        `}

        border: 1px solid ${fieldStateColor};
        transition: border-color ${theme.transition.timingFunction} ${theme.transition.duration};
        transition: box-shadow ${theme.transition.timingFunction} ${theme.transition.duration};

        &:hover {
            ${fieldState !== "disabled" && elevation(1)}
        }
    `}
`;

const inputWrapStyle = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
`;

const leftElementStyle = css`
    display: flex;
    align-items: center;
    ${m(0, 1, 0, 0)};
`;

const rightElementStyle = css`
    display: flex;
    align-items: center;
    ${m(0, 0, 0, 1)};
`;

const detailStyle = (theme: Theme) => css`
    ${m(0.5, 0, 0, 0)};
    color: ${theme.colors.gray["700"]};
`;
